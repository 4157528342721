/**
 * Enhances any group of elements by adding a text-search filter to them.
 * - You're able to define what wrapped content constitutes "one element" being searched against as a potential result.
 * - You're able to define which parts of that context element are "searchable" (by default, all of it).
 */
customElements.define(
	'vca-filter',
	class extends HTMLElement {
		// Setup variables used throughout this Class
			#setupComplete = false;
			#validConfiguration = false;
			#searchContexts = null;
			#filterLabel = "Filter by:"

		// Watch these component attributes for changes
			static observedAttributes = [
				'search-context',
				'search-targets'
			];

		/**
		 * The class constructor method.
		 * This is only ever called once per instance.
		 * At this point you can't add Nodes inside the normal DOM, and you can't add or set an attribute either; because it's not connected to the DOM yet.
		 */
		constructor() {
			// Inherit everything from the parent HTMLElement class
				super();

			// Now we can do setup stuff that only needs running once
		}

		/**
		 * Run methods when an instance of this custom element attaches to the DOM
		 * i.e., if some JS adds an instance of this Custom Element into the page, connectedCallback will fire.
		 */
		connectedCallback () {
			// Set up the rest of the Custom Element instance now it has access to the DOM
				this.setup();
		}

		/**
		 * When an instance is removed from the DOM this event allows us to clean up things previously done in connectedCallback.
		 */
		disconnectedCallback() {
			// e.g., emptying localStorage or something that might otherwise persist needlessly
		}

		/**
		 * React when the value of any of the observed attributes is changed on the component
		 * @param  {String} name     The attribute name
		 * @param  {String} oldValue The old attribute value
		 * @param  {String} newValue The new attribute value
		 */
		attributeChangedCallback (name, oldValue, newValue) {
			// re-build the searching based on what's changed
				this.getSearchContexts();
		}

		/**
		* Setup is in its own function so that it can be called from the constructor or connectedCallback, because depending on how the component is loaded you may need either of those.
		*/
		setup() {
			// Abort if the function has been run before
				if (this.#setupComplete) return;

			// Make sure we have search contexts (and targets if defined)
				this.getSearchContexts();
				this.getFilterLabel();

			// Insert the controls
				this.insertAdjacentHTML(
					"afterbegin",
					`
						<label class='vca-filter-ui'>
							<span>${this.#filterLabel}</span>
							<input type='text' class='vca-filter-input' placeholder='e.g., Bear Street'/>
						</label>`
				);

			// Watch for interactions on the search input and filter accordingly
				this.querySelector('.vca-filter-input')
				.addEventListener(
					'input',
					event => this.filterContexts( event )
				);

			// Flag that the setup function has been run
				this.#setupComplete = true;
		}

		getFilterLabel() {
			this.#filterLabel = this.getAttribute('filter-label') || "Filter by:";
		}

		/**
		 * Reads the properties on the custom element
		 */
		getSearchContexts() {
			this.findSearchContext = this.getAttribute('search-context') || null;
			this.findSearchTargets = this.getAttribute('search-targets') || null;

			if(!this.findSearchContext) {
				console.error('This component requires a search-context property value');
				this.#validConfiguration = false;
				return;
			}

			this.#validConfiguration = true;
			this.#searchContexts = this.querySelectorAll( `${this.findSearchContext}` );
			if( this.#searchContexts.length == 0 ) {
				console.warn(`The component does not have any child nodes that match the selector defined in the 'search-context' property - there is nothing to filter.`)
			}
		}

		/**
		 * Loops through the Search Contexts, and inspects all Search Targets' text content to see if there's a case-insensitive match for what's been typed in the text input.
		 * @param {*} event
		 */
		filterContexts( event ) {
			if( ! this.#validConfiguration ) {
				console.error('Impossible to filter as the configuration is invalid.');
				return;
			}

			this.lookingFor = event.target.value;

			this.#searchContexts.forEach( context => {
				this.contextHasMatch = false; // by default assume the context does not contain a match

				// Do we have specific search targets, or are we just checking the entire context?
					if( this.findSearchTargets ) {
						context.querySelectorAll( this.findSearchTargets ).forEach( searchTarget => {
							if( searchTarget.textContent.toLowerCase().includes( this.lookingFor.toLowerCase() ) ) {
								context.dataset.vcaFilterMatched = 'true';
								this.contextHasMatch = true; // If there's one match in the context that's enough to show the context; don't let future tests hide the context
							} else {
								if(!this.contextHasMatch) {
									context.dataset.vcaFilterMatched = 'false';
								}
							}
						});
					}
					else {
						if( context.textContent.toLowerCase().includes( this.lookingFor.toLowerCase() ) ) {
							context.dataset.vcaFilterMatched = 'true';
							this.contextHasMatch = true; // If there's one match in the context that's enough to show the context; don't let future tests hide the context
						} else {
							if(!this.contextHasMatch) {
								context.dataset.vcaFilterMatched = 'false';
							}
						}
					}
			});
		}
});
